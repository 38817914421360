<template>

  <div>
    <div class='home' style="height: 100%">
      <el-header style="padding: 0; margin: 0 auto">
        <header-layout></header-layout>
      </el-header>
      <div class="register clearfix">
        <div class="register-wrap">
          <el-row justify="center" type="flex">
            <el-form ref="loginForm" :model="user" label-width="110px" status-icon>
              <h2 style="">注&nbsp;&nbsp;册</h2>
              <hr>
              <el-form-item prop="username">
                <span slot="label" style="font-size:large">用户名&nbsp;</span>
                <el-input v-model="user.username" placeholder="请输入用户名"></el-input>
              </el-form-item>
              <el-form-item prop="email">
                <span slot="label" style="font-size:large">邮箱&nbsp;</span>
                <el-input v-model="user.email" placeholder="请输入邮箱"></el-input>
              </el-form-item>
              <el-form-item prop="imgVerify">
                <img id="imgV" slot="label" :src="imgUrl" alt="加-验-证码" @click="change()">
                <el-input v-model="user.imgVerify" placeholder="请输入左侧图形验证码"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class='verify' icon="el-icon-message" round size=small type="info"
                           @click="sendVerification()">发送邮箱验证码
                </el-button>
              </el-form-item>
              <el-form-item prop="verification">
                <span slot="label" style="font-size:large;">邮箱验证码</span>
                <el-input v-model="user.verification" placeholder="请输入验证码"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <span slot="label" style="font-size:large;">设置密码&nbsp;</span>
                <el-input v-model="user.password" placeholder="请输入密码" show-password></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <span slot="label" style="font-size:large;">确认密码&nbsp;</span>
                <el-input v-model="user.repeatPassword" placeholder="请确认密码" show-password
                          @keyup.enter.native="doRegister"></el-input>
              </el-form-item>

              <el-form-item>
                <router-link slot="label" style="margin-top:0.5rem; margin-bottom: 0.5rem; text-align: left; width: 100%"
                             to="/login">返回登陆&nbsp;
                </router-link>
                <el-button icon style="background-color:#1E90FF; font-size: medium;" type="primary"
                           @click="doRegister()">注册账号
                </el-button>
              </el-form-item>
            </el-form>
          </el-row>
        </div>
      </div>
    </div>
    <FooterLayout></FooterLayout>
  </div>

</template>

<script>
import {register, sendEmail, verifyImg} from '@/api/public'
import HeaderLayout from '../../components/HeaderLayout.vue'
import FooterLayoutSlim from '../../components/FooterLayoutSlim.vue'
import FooterLayout from '../../components/FooterLayout.vue'

export default {
  name: 'register',
  components: {
    FooterLayout,
    HeaderLayout,
    FooterLayoutSlim
  },
  data() {

    return {
      imgUrl: '',

      user: {
        username: '',
        imgVerify: '',
        email: '',
        password: '',
        repeatPassword: '',
        verification: ''
      },
    }
  },
  created() {
    this.change()
  },
  methods: {
    change() {
      let _this = this
      verifyImg().then(res => {

        _this.imgUrl = 'data:image/png;base64,' + btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))
        _this.$store.commit('setVerify', {verifyID: res.headers['verify-code-id']})
        console.log(_this.$store.state.verifyID)
        console.log(_this.imgUrl)
      })
    },

    sendVerification() {
      if (!this.user.email) {
        this.$message.error('请输入邮箱！')
        return
      } else {
        var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        if (!reg.test(this.user.email)) {
          this.$message.error('请输入有效的邮箱！')
        } else {
          sendEmail(this.user.email, this.user.imgVerify, this.$store.state.verifyID).then(res => {
            this.$message({
              message: '已发送，请到邮箱中查收！',
              type: 'success'
            })
          }).catch(() => {
            this.$message.error('验证码错误！')
          })

        }
      }
    },

    doRegister() {
      if (!this.user.username) {
        this.$message.error('请输入用户名！')
        return
      } else if (!this.user.email) {
        this.$message.error('请输入邮箱！')
        return
      } else if (this.user.email != null) {
        var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        if (!reg.test(this.user.email)) {
          this.$message.error('请输入有效的邮箱！')
        } else if (!this.user.password) {
          this.$message.error('请输入密码！')
          return
        } else if (this.user.password !== this.user.repeatPassword) {
          this.$message.error('两次输入密码不一致！')
        } else {
          // this.$router.push({ path: "/" }); //无需向后台提交数据，方便前台调试

          register(this.user.username, this.user.email, this.user.password, this.user.verification).then(res => {
            if (res.msg === '成功') {
              this.$message({
                message: '注册成功',
                type: 'success'
              })
              this.$router.push({path: '/login'})
            }
          }).catch(() => {
            this.$message.error('验证码或邮箱错误！')
          })

        }
      }
    },

    mounted() {
      if (this.$store.state.isLogin) {
        this.$message.info(this.$store.state.name + '，欢迎回来！')
        this.$router.push('/user')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.verify {
  /* margin: 20px; */
  margin-left: 50px;
  width: 100px;
}

.register {
  width: 100%;
  height: 100%;
  background: url("https://citi-1308543285.cos.ap-shanghai.myqcloud.com/userbg.png") repeat;
  overflow: hidden;
  animation: bg_anim 20s ease infinite;
  -webkit-animation: bg_anim 20s ease infinite;
  -moz-animation: bg_anim 20s ease infinite;
}

@keyframes bg_anim {
  0% {
    background-position: 0 0;
    opacity: 1;
    filter: brightness(1);
  }
  25% {
    background-position: 20% 0;
    opacity: 1;
    filter: brightness(0.8);
  }
  50% {
    background-position: 10% 10%;
    opacity: 1;
    filter: brightness(1.1);
  }
  75% {
    background-position: 20% 0;
    opacity: 1;
    filter: brightness(1);
  }
  100% {
    background-position: 0 0;
    opacity: 1;
    filter: brightness(1);
  }
}


.register-wrap {
  /* background: url("../assets/images/login_bg.png") no-repeat; */
  border-radius: 15px;
  background-size: cover;
  background-color: white;
  opacity: 0.8;
  width: 360px;
  height: 630px;
  margin: 128px auto;
  overflow: hidden;
  padding-top: 10px;
  line-height: 10px;
  box-shadow: 0 0 5px white;
}

h3 {
  color: #0babeab8;
  font-size: 24px;
}

hr {
  background-color: #444;
  margin: 20px auto;
}

.el-button {
  width: 80%;
  height: 100%;
  margin-left: -50px;
}

a {
  text-decoration: none;
  color: #aaa;
  font-size: 15px;
}

a:hover {
  color: coral;
}

#imgV {
  width: 80px;
  height: 30px;
}
</style>
